import { useProducts } from './use-products';
import { Button } from '@ui/buttons/button';
import {Trans} from '@ui/i18n/trans';
import { ForumIcon } from '@ui/icons/material/Forum';
import { Navbar } from '../../ui/navigation/navbar/navbar';
import { Link } from 'react-router-dom';
import { Footer } from '../../ui/footer/footer';
import { UpsellBillingCycle } from './find-best-price';
import { BillingCycleRadio } from './billing-cycle-radio';
import { StaticPageTitle } from '../../seo/static-page-title';
import { PricingTable } from '@common/billing/pricing-table/pricing-table';
import { FormattedCurrency } from '@ui/i18n/formatted-currency';
import { apiClient } from '@common/http/query-client';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, Fragment } from 'react';

export function PricingPage() {
  const query = useProducts('pricingPage');
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('yearly');

  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Pricing" />
      </StaticPageTitle>
      <Navbar
        color="bg"
        darkModeColor="transparent"
        border="border-b"
        menuPosition="pricing-table-page"
      />
      <div className="container mx-auto px-24">
        <h1 className="mb-30 mt-30 text-center text-3xl font-normal md:mt-60 md:text-4xl md:font-medium">
          <Trans message="Choose the right plan for you" />
        </h1>

        <BillingCycleRadio
          products={query.data?.products}
          selectedCycle={selectedCycle}
          onChange={setSelectedCycle}
          className="mb-40 flex justify-center md:mb-70"
          size="lg"
        />

        <PricingTable
          selectedCycle={selectedCycle}
          productLoader="pricingPage"
        />
        <ContactSection />
      </div>
      <Footer className="container mx-auto flex-shrink-0 px-24" />
    </Fragment>
  );
}

function ContactSection() {
  return (
    <div className="my-20 p-24 text-center md:my-80">
      <ForumIcon size="xl" className="text-muted" />
      <div className="my-8 md:text-lg">
        <Trans message="Do you have any questions about PRO accounts?" />
      </div>
      <div className="mb-24 mt-20 text-sm md:mt-0 md:text-base">
        <Trans message="Our support team will be happy to assist you." />
      </div>
      <Button variant="flat" color="primary" elementType={Link} to="/contact">
        <Trans message="Contact us" />
      </Button>
    </div>
  );
}

export const SuccessCheckout = () => {
  const [invoice, setInvoice] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // درخواست برای ایجاد فاکتور
    apiClient.post('billing/invoices/create')
      .then(response => {
        setInvoice(response.data.invoice);
      })
      .catch(error => {
        console.error('Failed to create invoice:', error);
        navigate('/error'); // هدایت به صفحه خطا در صورت شکست
      });
  }, []);
  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Pricing" />
      </StaticPageTitle>
      <Navbar
        color="bg"
        darkModeColor="transparent"
        border="border-b"
        menuPosition="pricing-table-page"
      />
      <div className="flex h-screen items-center justify-center bg-gradient-to-r">
        <div className="flex w-full max-w-md flex-col gap-4 rounded-lg bg-white p-16 text-center shadow-lg">
          <h1 className="text-green-600 mb-4 flex items-center justify-center text-xl font-bold text-positive">
            🎉 <Trans message="Checkout Successful!" />
          </h1>
          <p className=" mb-6 text-base font-medium text-positive">
            <Trans message="Thank you for your purchase! Your order has been processed successfully." />
          </p>
          <a
            href="/"
            className="bg-green-600 hover:bg-green-700 inline-block transform rounded border-positive-darker bg-positive px-4 py-2 text-white  transition duration-200 hover:scale-105"
          >
            <Trans message="Go to Homepage" />
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export const ErrorCheckout = () => {
  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Pricing" />
      </StaticPageTitle>
      <Navbar
        color="bg"
        darkModeColor="transparent"
        border="border-b"
        menuPosition="pricing-table-page"
      />
      <div className="flex h-screen items-center justify-center bg-gradient-to-r">
        <div className="flex w-full max-w-md flex-col gap-8 rounded-lg bg-white p-16 text-center shadow-lg">
          <h1 className="mb-4 flex items-center justify-center text-xl font-bold text-danger">
            ❌ <Trans message="Checkout Failed!" />
          </h1>
          <p className="mb-6 text-base font-medium text-danger">
            <Trans message="Unfortunately, there was a problem processing your payment. Please try again." />
          </p>
          <p className="mb-4 font-bold text-danger">
            <Trans message="If the problem persists, contact support." />
          </p>
          <a
            href="/pricing"
            className="bg-red-600 hover:bg-red-700 inline-block transform rounded border-danger-darker bg-danger px-4 py-2 transition duration-200 hover:scale-105"
          >
            <Trans message="Try Again" />
          </a>
        </div>
      </div>
    </Fragment>
  );
};
