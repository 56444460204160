import React, { useState, useRef, useEffect } from 'react';
import { Trans } from '@ui/i18n/trans';

interface VerificationInputProps {
  values: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
}

const VerificationInput: React.FC<VerificationInputProps> = ({ values, setValues }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value;
    if (value.length > 1) return; // جلوگیری از وارد کردن بیش از یک کاراکتر

    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    // انتقال خودکار به ورودی بعدی در صورت ورود مقدار
    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }

    // اگر تمام ورودی‌ها پر شدند، کد را جمع‌آوری کنید
    const fullCode = newValues.join('');
    if (fullCode.length === 4) {
      // کد کامل شده است
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace' && !values[index] && index > 0) {
      inputRefs.current[index - 1]?.focus(); // انتقال به ورودی قبلی در صورت فشردن Backspace
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('text').slice(0, 4); // محدود کردن داده‌های کپی شده به ۴ کاراکتر
    if (pasteData.length === 4) {
      setValues(pasteData.split(''));
      inputRefs.current[3]?.focus(); // فوکوس بر روی ورودی آخر
    }
    e.preventDefault();
  };

  return (
    <>
      <label
        htmlFor=""
        className="verification-input-label"
      >
      <Trans message="Enter code you received" />
      </label>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          margin: '1rem 0 1rem 0',
          justifyContent: 'center',
        }}
      >
        {values.map((value, index) => (
          <input
            key={index}
            type="text"
            inputMode="numeric"
            maxLength={1}
            value={value}
            onChange={e => handleChange(e, index)}
            onKeyDown={e => handleKeyDown(e, index)}
            onPaste={handlePaste}
            ref={el => (inputRefs.current[index] = el)}
            style={{
              height: '50px',
              width: '50px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#000',
            }}
          />
        ))}
      </div>
    </>
  );
};

export default VerificationInput;
