import React, { useState, useEffect, useContext } from 'react';
import { Link, Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { Button } from '@ui/buttons/button';
import { Form } from '@ui/forms/form';
import { LinkStyle } from '@ui/buttons/external-link';
import {
  RegisterPayload,
  useRegister,
  useSendOtp,
  useVerifyOtp,
  RegisterPhonePayload,
  OtpPayload,
} from '../requests/use-register';
import { SocialAuthSection } from './social-auth-section';
import { AuthLayout } from './auth-layout/auth-layout';
import { Trans } from '@ui/i18n/trans';
import VerificationInput from './verificationInput';
import { FormCheckbox } from '@ui/forms/toggle/checkbox';
import { CustomMenuItem } from '../../menus/custom-menu';
import { useRecaptcha } from '../../recaptcha/use-recaptcha';
import { StaticPageTitle } from '../../seo/static-page-title';
import { useSettings } from '@ui/settings/use-settings';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import {FaRegEdit} from 'react-icons/fa';
import './register-page.css'; // Import the CSS file for custom styles

interface Props {
  inviteType?: string;
}

export function RegisterPage({ inviteType }: Props) {
  const {
    branding,
    registration: { disable },
    social,
  } = useSettings();
  const { auth } = useContext(SiteConfigContext);
  const { verify, isVerifying } = useRecaptcha('register');

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isRegisteringUsingInvite =
    pathname.includes('workspace') || !!inviteType;
  const searchParamsEmail = searchParams.get('email') || undefined;

  const formPhone = useForm<RegisterPhonePayload>();
  const formOtp = useForm<OtpPayload>();
  const formRegister = useForm<RegisterPayload>({
    defaultValues: { email: searchParamsEmail },
  });

  const register = useRegister(formRegister);
  const sendOtp = useSendOtp(formPhone);
  const verifyOtp = useVerifyOtp(formOtp);

  const [mode, setMode] = useState<'phone' | 'otp' | 'register'>('phone');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [otpValues, setOtpValues] = useState<string[]>(Array(4).fill(''));

  useEffect(() => {
    const fullCode = otpValues.join('');
    formOtp.setValue('code', fullCode);
  }, [otpValues, formOtp]);

  useEffect(() => {
    formOtp.setValue('phone_number', phoneNumber);
  }, [phoneNumber, formOtp]);
  
useEffect(() => {
  formRegister.setValue('phone_number', phoneNumber); // ثبت مقدار phone_number در فرم
}, [phoneNumber, formRegister]);

  if (disable && !isRegisteringUsingInvite) {
    return <Navigate to="/login" replace />;
  }

  let heading = <Trans message="Create a new account" />;
  if (isRegisteringUsingInvite) {
    heading = (
      <Trans
        values={{ siteName: branding?.site_name }}
        message="To join your team on :siteName, create an account"
      />
    );
  }

  // Define the submit handlers with the correct types
  const handlePhoneSubmit: SubmitHandler<RegisterPhonePayload> = (payload) => {
    setPhoneNumber(payload.phone_number);
    sendOtp.mutate(payload, {
      onSuccess: () => setMode('otp'),
    });
  };

const handleOtpSubmit: SubmitHandler<OtpPayload> = (payload) => {
  verifyOtp.mutate(payload, {
    onSuccess: (response) => {
      setPhoneNumber(response.phone_number); // ذخیره شماره موبایل از پاسخ سرویس
      setMode('register'); // تغییر حالت به فرم ثبت‌نام نهایی
    },
  });
};

  const handleRegisterSubmit: SubmitHandler<RegisterPayload> = async (payload) => {
    const isValid = await verify();
    if (isValid) {
      register.mutate({
        ...payload,
        invite_type: inviteType,
      });
    }
  };

  return (
    <AuthLayout
      heading={heading}
      message={
        <Trans
          values={{
            a: (parts) => (
              <Link className={LinkStyle} to="/login">
                {parts}
              </Link>
            ),
          }}
          message="Already have an account? <a>Sign in.</a>"
        />
      }
    >
      <StaticPageTitle>
        <Trans message="Register" />
      </StaticPageTitle>
      {mode === 'phone' && (
        <Form form={formPhone} onSubmit={handlePhoneSubmit}>
          <FormTextField
            className="mb-32 custom-textfield"
            name="phone_number"
            type="tel"
            label={<Trans message="Phone Number" />}
            required
          />
          <Button
            className="block w-full"
            type="submit"
            variant="flat"
            color="primary"
            size="md"
            disabled={sendOtp.isPending}
          >
            <Trans message="Continue" />
          </Button>
        </Form>
      )}
      {mode === 'otp' && (
        <Form form={formOtp} onSubmit={handleOtpSubmit}>
          <VerificationInput values={otpValues} setValues={setOtpValues} />
            <div className="mb-32 flex items-center gap-x-6 text-xs">
            <button
              className="flex items-center"
              type="button"
              onClick={() => setMode('phone')}
              style={{ color: '#FFA500'}}
            >
              <FaRegEdit size={12} /> <Trans message="Edit phone number" />
            </button>
            <span>{phoneNumber}</span>
          </div>
          <Button
            className="block w-full"
            type="submit"
            variant="flat"
            color="primary"
            size="md"
            disabled={verifyOtp.isPending}
          >
            <Trans message="Verify and Continue" />
          </Button>
        </Form>
      )}
      {mode === 'register' && (
        <Form form={formRegister} onSubmit={handleRegisterSubmit}>
    <input
      type="hidden"
      {...formRegister.register('phone_number')} // ثبت فیلد در فرم
      value={phoneNumber}
    />    
          <FormTextField
            className="mb-32 custom-textfield"
            name="email"
            type="email"
            disabled={!!searchParamsEmail}
            label={<Trans message="Email" />}
            required
          />
          <FormTextField
            className="mb-32 custom-textfield"
            name="password"
            type="password"
            label={<Trans message="Password" />}
            required
          />
          <FormTextField
            className="mb-32 custom-textfield"
            name="password_confirmation"
            type="password"
            label={<Trans message="Password Confirmation" />}
            required
          />
          {auth?.registerFields ? <auth.registerFields /> : null}
          <PolicyCheckboxes />
          <Button
            className="block w-full"
            type="submit"
            variant="flat"
            color="primary"
            size="md"
            disabled={register.isPending || isVerifying}
          >
            <Trans message="Create account" />
          </Button>
        </Form>
      )}
      <SocialAuthSection
        dividerMessage={
          social.compact_buttons ? (
            <Trans message="Or sign up with" />
          ) : (
            <Trans message="OR" />
          )
        }
      />
    </AuthLayout>
  );
}

function PolicyCheckboxes() {
  const {
    registration: { policies },
  } = useSettings();

  if (!policies) return null;

  return (
    <div className="mb-32">
      {policies.map((policy) => (
        <FormCheckbox
          key={policy.id}
          name={policy.id}
          className="mb-4 block"
          required
        >
          <Trans
            message="I accept the :name"
            values={{
              name: (
                <CustomMenuItem
                  unstyled
                  className={() => LinkStyle}
                  item={policy}
                />
              ),
            }}
          />
        </FormCheckbox>
      ))}
    </div>
  );
}
