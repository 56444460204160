import React from 'react';
import { Price } from './price';
import { Trans } from '@ui/i18n/trans';
import clsx from 'clsx';

interface FormattedPriceProps {
  price?: Omit<Price, 'id'>;
  variant?: 'slash' | 'separateLine';
  className?: string;
  priceClassName?: string;
  periodClassName?: string;
}

// تابع کمکی برای فرمت کردن و تبدیل به تومان
function formatCurrencyToToman(amount: number): string {
  const amountInToman = amount / 10;
  return `${amountInToman.toLocaleString('fa-IR')} تومان`;
}

export function FormattedPrice({
  price,
  variant = 'slash',
  className,
  priceClassName,
  periodClassName,
}: FormattedPriceProps) {
  if (!price) return null;

  const translatedInterval = <Trans message={price.interval} />;

  return (
    <div className={clsx('flex items-center gap-6', className)}>
      {variant === 'slash' ? (
        <div className={periodClassName}> / {translatedInterval}</div>
      ) : (
        <div className={periodClassName}>
          <Trans message="per" /> <br /> {translatedInterval}
        </div>
      )}    
        <div className={priceClassName} style={{ direction: 'rtl' }}>
          {formatCurrencyToToman(price.amount / (price.interval_count ?? 1))}
        </div>
    </div>
  );
}
